import { Injectable } from "@angular/core";

@Injectable()
export class Constants {
  constructor() {
    // do nothing.
  }
  public localUrl = "https://localhost:4200";
  public TIME_OUT = 30; // in sec
  public serverUrl = location.origin;
  public cookieExpireTime = 240; // in hours
  public AUTHORIZATION = "Authorization";
  public prependTokenString = "Bearer ath-";
  public TOKEN_HEADER = "authorization";
  public API_TOKEN = "apiAccessToken";
  public USER_NAME = "USER";
  public USER_ROLE = "role";
  public USER_FULL_NAME = "USER_FULL_NAME";
  public HEADER = "HEADER";
  public RESPONSE = "RESPONSE";
  public PROPERTY = "PROPERTY";
  public CHOICE = "CHOICE";
  public TITLE = "Title";
}
