import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { CONSTANTS } from "src/app/assets/data/i18";
import { EntityData } from "src/app/assets/data/constant-values";
import { AuthService } from "src/app/shared/services/authentication/auth.service";
import { ElevateSafetyDetectiveService } from "src/app/shared/services/elevateSafety-detective/elevate-safety-detective.service";
import { CommandCenterService } from "src/app/shared/services/command-center/command-center.service";

@Component({
  selector: "app-file-review",
  templateUrl: "./file-review.component.html",
  styleUrls: ["./file-review.component.scss"],
})
export class FileReviewComponent implements OnInit {
  constants: any = CONSTANTS;
  @Output() isEditEnable:EventEmitter<boolean> = new EventEmitter;
  @Output() columnCollapsed: EventEmitter<boolean> = new EventEmitter();
  @Output() recallTaskDetail: EventEmitter<boolean> = new EventEmitter();
  @Input() taskDetails: any = EntityData.TASK_DETAIL_RESPONSE;
  isCriteriaCollapsed: boolean = false;
  showAllEventCriteria: boolean = false;
  deepCopyArray:any = [];
  initialized=false;
  adjudicateAuditArr: any[] = [];
  auditReqObj: any;
  userName:string = '';
  disableSections: any = JSON.parse(JSON.stringify(EntityData.DISABLE_FORM_FLAGS));
  disableResetIcons: any = JSON.parse(JSON.stringify(EntityData.DISABLE_FORM_FLAGS));
  expansionFlags: any = JSON.parse(JSON.stringify(EntityData.DISABLE_FORM_FLAGS));
  indicatorFlags: any = JSON.parse(JSON.stringify(EntityData.DISABLE_FORM_FLAGS));
  containerEnabled:boolean = false;
  fileReviewHeading = CONSTANTS.fileReviewHeading;
  user:any = localStorage.getItem('USER');

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public elevateSafetyDetectiveService: ElevateSafetyDetectiveService,
    public commandCenterService: CommandCenterService,
    public authService: AuthService,
    public datepipe: DatePipe,
    ) {}

  ngOnInit(): void {
    this.userName = this.user;
    this.deepcopyHandler(this.taskDetails);
    this.manageEventCriteriaSelection(this.taskDetails.caseValidityCriteria.isEventDetected);
    this.onloadValidations();
    this.initialized = true;
  }

  ngOnChanges() {
    if(this.initialized) {
      this.deepcopyHandler(this.taskDetails);
      this.manageEventCriteriaSelection(this.taskDetails.caseValidityCriteria.isEventDetected);
      this.resetTaskFlags();
      this.onloadValidations();
    }
  }

  selectedFileReview(option: string, formControlName: string): void {
    this.disableResetIcons[formControlName] = false;
    switch (formControlName) {
      case "eventDetectionForm": {
        this.populateAdjudicateAuditLogs(formControlName,this.taskDetails.caseValidityCriteria.isEventDetected,option);
        this.taskDetails.caseValidityCriteria.isEventDetected = option;
        this.manageEventCriteriaSelection(option);
        break;
      }
      case "productComplainaintForm": {
        this.populateAdjudicateAuditLogs(formControlName,this.taskDetails.caseValidityCriteria.isProductQualityComplaint,option);
        this.taskDetails.caseValidityCriteria.isProductQualityComplaint =
          option;
        break;
      }
    }
    this.showIndication(formControlName)
  }

  //Copy the Task Detail Response into another object for reset purpose and validations
  deepcopyHandler(data: any): void {
    this.deepCopyArray = JSON.parse(JSON.stringify(data));
  }

  completeTask(): void {
    const route: any = this.route.snapshot.paramMap.get('document-id');
    this.elevateSafetyDetectiveService.completeTask(route, this.taskDetails).subscribe((data: any) => {
      this.authService.showSuccessErrorPopup("success", CONSTANTS.taskCompletedMsg);
      this.isEditEnable.emit(false);
      const url = `/home/task-queue`;
      this.router.navigate([url]);
    })
    this.commandCenterService.adjudicationAuditLogs(this.adjudicateAuditArr).subscribe((data: any) => {
    })
  }

  //Reseting the data with the base data.
  resetTaskFlags(){
    this.disableSections= JSON.parse(JSON.stringify(EntityData.DISABLE_FORM_FLAGS));
    this.disableResetIcons= JSON.parse(JSON.stringify(EntityData.DISABLE_FORM_FLAGS));
    this.expansionFlags= JSON.parse(JSON.stringify(EntityData.DISABLE_FORM_FLAGS));
    this.indicatorFlags = JSON.parse(JSON.stringify(EntityData.DISABLE_FORM_FLAGS));
  }
  
  onloadValidations(){
    const claimed = this.taskDetails.isClaimed
    if(!claimed) {
      this.containerEnabled = false;
      return;
    }
    else {
      this.containerEnabled = true;
      this.showAllEventCriteria = true;
      this.expansionFlags = JSON.parse(JSON.stringify(EntityData.ENABLE_FORM_FLAGS));
    }
    if(this.taskDetails.assignee !== this.userName) {
      this.containerEnabled = false;
    }
  }

  manageEventCriteriaSelection(val:string){
    this.showAllEventCriteria  = (val === 'true') ? true : false;
  }

  resetReviewForms(formControlName:string) {
    let mockData:any = EntityData.FILE_REVIEW_DATA;
    let fieldName: string;
    if(mockData[formControlName] && mockData[formControlName]['fieldRequestValue'] ){
      fieldName = mockData[formControlName]['fieldRequestValue'];
      this.selectedFileReview(this.deepCopyArray.caseValidityCriteria[fieldName],formControlName);
    }
    this.disableResetIcons[formControlName] = true;
  }

  showIndication(formName:string) {
    let mockData:any = EntityData.FILE_REVIEW_DATA;
    if(mockData[formName] && mockData[formName]['fieldRequestValue']){
      let fieldRequestValue = mockData[formName]['fieldRequestValue'];
      this.indicatorFlags[formName] = this.taskDetails.caseValidityCriteria[fieldRequestValue]  === this.deepCopyArray.caseValidityCriteria[fieldRequestValue];
    }
  }


  changeFormsValues(formName:string,status:string){
    let mockData:any = EntityData?.FILE_REVIEW_DATA;
    if(mockData[formName] && mockData[formName]['fieldRequestValue']){
      let fieldRequestValue = mockData[formName]['fieldRequestValue'];
      if(this.taskDetails.caseValidityCriteria[fieldRequestValue]){
        let disagreeVal = this.deepCopyArray.caseValidityCriteria[fieldRequestValue] === 'true' ? 'false' : 'true';
        let val = (status === 'agree') ? this.deepCopyArray.caseValidityCriteria[fieldRequestValue] : disagreeVal;
        this.selectedFileReview(val,formName);
      }
    }
  }

  editClick(formName:string){
    this.disableSections[formName] = false;
    this.expansionFlags[formName] = false;
    this.isEditEnable.emit(true);
  }

  populateAdjudicateAuditLogs(formName:string,oldValue:string ,newValue:string){
    let mockData:any = EntityData.FILE_REVIEW_DATA;
    if(mockData[formName]  && mockData[formName]['fieldRequestValue']){
      newValue = (newValue == 'true') ? mockData[formName]['detected'] : ((newValue == 'false') ? mockData[formName]['notDetected'] : '');
      oldValue = (oldValue == 'true') ? mockData[formName]['detected'] : ((newValue == 'false') ? mockData[formName]['notDetected'] : '');
      const reqObj = {
        "documentId": this.route.snapshot.paramMap.get('document-id'),
        "userId":this.userName,
        "dateTime":this.datepipe.transform(new Date(),"dd-MM-YYYY HH:mm:ss"),
        "field":mockData[formName]['field'] + newValue,
        "reviewType":this.taskDetails.taskType,
        "oldValue": oldValue,
        "newValue": newValue
      }
      this.adjudicateAuditArr.push(reqObj);
    }
  }
  
  collapseColumn(): void {
    this.isCriteriaCollapsed = !this.isCriteriaCollapsed;
    this.columnCollapsed.emit(this.isCriteriaCollapsed);
  }

  expandColumn(): void {
    this.isCriteriaCollapsed = false;
    this.columnCollapsed.emit(false);
  }

  backToList(): void {
    let stat = false;
    for (const [key, value] of Object.entries(this.disableSections)) {
      if(this.disableSections[key] === false) {
        stat = true;
        break;
      }
    }
    this.isEditEnable.emit(stat);
    const url = `/home/task-queue`;
    this.router.navigate([url]);
  }
}