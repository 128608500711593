import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { CONSTANTS } from "src/app/assets/data/i18";
import { CommandCenterService } from "src/app/shared/services/command-center/command-center.service";
import { DirtyComponent } from "src/app/shared/modules/dirty-component";
import { AuthService } from "src/app/shared/services/authentication/auth.service";
import {
  DropdownLists,
  TableColumnNames,
} from "src/app/assets/data/constant-values";

@Component({
  selector: "app-retrospective-qc-sampling",
  templateUrl: "./retrospective-qc-sampling.component.html",
  styleUrls: ["./retrospective-qc-sampling.component.scss"],
})
export class RetrospectiveQcSamplingComponent
  implements OnInit, DirtyComponent
{
  @ViewChild(MatSort) sort: MatSort | any;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  constants: any = CONSTANTS;
  isDirty = false;
  displayedColumns: string[] = TableColumnNames.RESTROSPECTIVE_QUALITY_SAMPLING;
  dataSource = new MatTableDataSource<any>();
  frequencyList: string[] = [];
  periodboundList: string[] = [];
  samplingboundList: string[] = [];
  retrospectiveQualityForm!: FormGroup;
  retrospectiveSaveMsg: string = CONSTANTS.retrospectiveSaveMsg;
  retrospectiveErrorMsg: string = CONSTANTS.retrospectiveErrorMsg;

  constructor(
    private commandCenterService: CommandCenterService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private authService: AuthService
  ) {
    this.rqcFormHandler(DropdownLists.RETROSPECTIVE_QUALITY_INTERFACE);
  }

  ngOnInit() {
    this.onLoadSetValuesHandler();
    this.getDropdownDataFromStorage();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  canDeactivate() {
    return this.isDirty;
  }

  // to create form for retrospective quality sampling
  rqcFormHandler(data: any): void {
    if (data) {
      const group: any = {};
      for (let i = 0; i < data?.length; i++) {
        group["frequency_" + i] = new FormControl();
        group["periodEnd_" + i] = new FormControl();
        group["samplingRate_" + i] = new FormControl();
      }
      this.retrospectiveQualityForm = this.fb.group(group);
    }
  }

  // on load get and patch the data to the forms field
  onLoadSetValuesHandler(): void {
    this.commandCenterService?.getListOfFrequency().subscribe(
      (data: any) => {
        if (data?.body) {
          this.updateAndPopulatedFormValues(data?.body);
        }
      },
      (error) => this.authService?.handleError(error)
    );
  }

  // to enable any RQC row to update data.
  enableRow(
    i: number,
    row: { isEditEnable: boolean; frequency: string }
  ): void {
    const selectedFrequency = row.frequency;
    row.isEditEnable = !row.isEditEnable;
    this.retrospectiveQualityForm?.controls["frequency_" + i]?.enable();
    this.retrospectiveQualityForm?.controls["samplingRate_" + i]?.enable();
    this.selectedRQCFrequency(selectedFrequency, i);
    this.isDirty = true;
  }

  // to update any row record's.
  save(i: number, row: any): void {
    const selectedFrequency =
      this.retrospectiveQualityForm.controls["frequency_" + i]?.value;
    const electedPeriodEnd =
      selectedFrequency === "Daily" || selectedFrequency === "Monthly"
        ? ""
        : this.retrospectiveQualityForm.controls["periodEnd_" + i]?.value;
    let params = {
      rpcId: row?.rpcId,
      documentType: row?.documentType,
      frequency:
        this.retrospectiveQualityForm.controls["frequency_" + i]?.value,
      periodEnd: electedPeriodEnd,
      samplingRate:
        this.retrospectiveQualityForm.controls[
          "samplingRate_" + i
        ]?.value.split("%")[0],
    };
    if (params.frequency == "Weekly" || params.frequency == "Biweekly") {
      if (params.periodEnd == "") {
        this.retrospectiveQualityForm.controls["periodEnd_" + i].setErrors({
          incorrect: true,
        });
        this.authService.showSuccessErrorPopup(
          "error",
          this.retrospectiveErrorMsg
        );
        return;
      } else {
        row.isEditEnable = !row.isEditEnable;
        this.saveRetrospectiveDataAndLoadGrid(params);
      }
    } else {
      row.isEditEnable = !row.isEditEnable;
      this.saveRetrospectiveDataAndLoadGrid(params);
    }
  }

  saveRetrospectiveDataAndLoadGrid(params: any) {
    this.commandCenterService.postListOfFrequency(params).subscribe(
      (data: any) => {
        this.updateAndPopulatedFormValues(data?.body);
        this.authService.showSuccessErrorPopup(
          "success",
          this.retrospectiveSaveMsg
        );
      },
      (error) => {
        this.authService.handleError(error);
        this.isDirty = true;
      }
    );
  }

  updateAndPopulatedFormValues(data: any): void {
    this.rqcFormHandler(data);
    this.patchValuesHandler(data);
    this.dataSource.data = data;
    this.disableRQCForms();
    this.isDirty = false;
  }

  // to set response of API's as values in the form field's.
  patchValuesHandler(data: any): void {
    for (let i = 0; i < data?.length; i++) {
      this.retrospectiveQualityForm.controls["frequency_" + i]?.setValue(
        data[i].frequency
      );
      this.retrospectiveQualityForm.controls["periodEnd_" + i]?.setValue(
        data[i].periodEnd
      );
      this.retrospectiveQualityForm.controls["samplingRate_" + i]?.setValue(
        data[i].samplingRate + "%"
      );
      data[i].isEditEnable = false;
    }
  }

  // to disable all form-field's as disable.
  disableRQCForms(): void {
    for (const i in this.dataSource?.data) {
      this.retrospectiveQualityForm.controls["frequency_" + i]?.disable();
      this.retrospectiveQualityForm.controls["periodEnd_" + i]?.disable();
      this.retrospectiveQualityForm.controls["samplingRate_" + i]?.disable();
    }
  }

  // to enable/diable period-end form field only when frequency is selected to either Weekly or Biweekly.
  selectedRQCFrequency(selectedFrequency: string, i: number): void {
    switch (selectedFrequency) {
      case "Weekly": {
        this.retrospectiveQualityForm.controls["periodEnd_" + i]?.enable();
        return;
      }
      case "Monthly": {
        this.retrospectiveQualityForm.controls["periodEnd_" + i]?.disable();
        this.retrospectiveQualityForm.controls["periodEnd_" + i]?.setValue("");
        return;
      }
      case "Biweekly": {
        this.retrospectiveQualityForm.controls["periodEnd_" + i]?.enable();
        return;
      }
      case "Daily": {
        this.retrospectiveQualityForm.controls["periodEnd_" + i]?.disable();
        this.retrospectiveQualityForm.controls["periodEnd_" + i]?.setValue("");
        return;
      }
    }
  }

  // to get the dynamic dropdown value.
  getDropdownDataFromStorage(): void {
    let dropdownData: any = JSON.parse(
      localStorage.getItem(this.constants.dropdown_data) || "{}"
    );
    this.frequencyList = dropdownData?.RETROSPECTIVE_QUALITY_FREQUENCY_LIST;
    this.periodboundList = dropdownData?.RETROSPECTIVE_QUALITY_PERIOD_END_LIST;
    this.samplingboundList = dropdownData?.SAMPLING_RATE;
  }
}
