import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { takeWhile } from "rxjs/operators";
import { CONSTANTS } from "src/app/assets/data/i18";
import { AuthService } from "src/app/shared/services/authentication/auth.service";
import { ElevateSafetyDetectiveService } from "src/app/shared/services/elevateSafety-detective/elevate-safety-detective.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  constant: any = CONSTANTS;
  resetForm!: FormGroup;
  alive = true;
  isOTPSent: boolean = false;

  constructor(
    private fb: FormBuilder,
    public elevateSafetyDetectiveService: ElevateSafetyDetectiveService,
    private authService: AuthService,
    public router: Router
  ) {
    this.createResetForm();
  }

  ngOnInit(): void {
    //do nothing
  }

  createResetForm(): void {
    this.resetForm = this.fb.group({
      userName: new FormControl("", [
        Validators.required,
        Validators.email,
        Validators.pattern("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,63}$"),
      ]),
    });
  }

  // to send OTP to entered mail-is to verify.
  OTPVerificationHandler(): void {
    if (this.resetForm.valid) {
      const payload = { username: this.resetForm.value["userName"] };
      this.elevateSafetyDetectiveService
        .sendResetPassword(payload)
        .pipe(takeWhile(() => this.alive))
        .subscribe((data: any) => {
          if (data?.body?.statusCode === 200) {
            this.resetForm.controls["userName"].setValue(payload.username);
            this.isOTPSent = true;
            this.authService.showSuccessErrorPopup(
              "success",
              data?.body?.statusMessage
            );
            this.createDynamicForm();
          } else {
            this.authService.showSuccessErrorPopup(
              "error",
              data?.body?.statusMessage
            );
          }
        });
    } else {
      return;
    }
  }

  // to reset password
  passwordResetHandler(): void {
    if (this.resetForm.valid) {
      const payload = {
        username: this.resetForm.value["userName"],
        newpw: this.resetForm.value["newPassword"],
        code: this.resetForm.value["resetCode"],
      };
      this.elevateSafetyDetectiveService
        .resetPassword(payload)
        .pipe(takeWhile(() => this.alive))
        .subscribe((data: any) => {
          if (data?.body?.statusCode === 200) {
            this.authService.showSuccessErrorPopup(
              "success",
              data?.body?.statusMessage
            );
            this.router.navigate(["login"]);
          } else {
            this.authService.showSuccessErrorPopup(
              "error",
              data?.body?.statusMessage
            );
          }
        });
    } else {
      return;
    }
  }

  // if user get's OTP than, create new form controls
  createDynamicForm(): void {
    this.resetForm = this.fb.group({
      ...this.resetForm.controls,
      resetCode: ["", Validators.required],
      newPassword: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{4,12}$"
          ),
        ],
      ],
    });
  }
}
