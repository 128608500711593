<div class="userContainer">
  <div class="userInfo">
    <h2 class="username">{{ constants?.hello }}, {{ userName }}</h2>
    <h2 class="welcome">{{ constants?.welcomeMSG }}
      <span id="safetyText">{{ constants?.safetyText }}<sup>TM</sup> </span>
      <span id="detectiveText"> {{ constants?.detectiveText }}</span>
    </h2>
  </div>
  <div class="userDetails">
    <h2 class="clickText paragraphFont">{{ constants?.learnMoreMSG }}</h2>
    <h2 *ngIf="userRole === ''" class="clickText paragraphFont">{{ constants?.noRoleAssignedContactAdmin }}</h2>
    <img src="../../../app/assets/images/Landing-Page-Illustration.svg" alt="Landing-Page-Illustration" title="Landing-Page-Illustration">
  </div>
</div>
