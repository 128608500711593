import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoaderComponent } from "./components/loader/loader.component";
import { WaveSpinnerComponent } from "./components/wave-spinner/wave-spinner.component";
import { MatModule } from "./modules/mat/mat.module";
import { ReusableDialogComponent } from "./components/reusable-dialog/reusable-dialog.component";

@NgModule({
  declarations: [
    LoaderComponent,
    WaveSpinnerComponent,
    ReusableDialogComponent,
  ],
  imports: [CommonModule, MatModule],
  exports: [LoaderComponent, WaveSpinnerComponent, MatModule],
  entryComponents: [],
})
export class SharedModule {}
