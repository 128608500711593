import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  isLoading = new BehaviorSubject<boolean>(false);
  $isLoading = this.isLoading.asObservable();

  constructor() {
    // do nothing.
  }

  enableLoader(status: boolean): any {
    this.isLoading.next(status);
  }
}