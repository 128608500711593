import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { takeWhile } from "rxjs/operators";
import { AuthService } from "src/app/shared/services/authentication/auth.service";
import { CookieService } from "ngx-cookie-service";
import { Constants } from "src/app/constants";
import { MatDialog } from "@angular/material/dialog";
import { CONSTANTS } from "src/app/assets/data/i18";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  constant: any = CONSTANTS;
  loginForm: FormGroup | any;
  name = " ";
  userData: any;
  private alive = true;
  credentialsErrorMsg: string = CONSTANTS.wrongCredentials;
  showNewPassword: boolean = false;

  constructor(
    public router: Router,
    public auth: AuthService,
    private cookieService: CookieService,
    public constants: Constants,
    public dialog: MatDialog
  ) {
    this.loginForm = new FormGroup({
      userName: new FormControl("", [
        Validators.required,
        Validators.email,
        Validators.pattern("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,63}$"),
      ]),
      password: new FormControl("", [
        Validators.required,
        Validators.pattern(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{4,12}$"
        ),
      ]),
    });
  }

  ngOnInit(): void {
    // do nothing.
  }

  onSubmit() {
    if (!this.loginForm.valid) {
      return;
    }
    if (!this.showNewPassword) {
      this.login();
    } else {
      this.resetPassword();
    }
  }

  login() {
    if (this.cookieService.check(this.constants.TOKEN_HEADER)) {
      this.auth.clearCacheData();
    }
    this.auth
      .login(this.loginForm?.value)
      ?.pipe(takeWhile(() => this.alive))
      .subscribe(
        (res) => this.loginSuccess(res),
        (error) => {
          this.auth.clearCacheData();
          this.auth.handleError(error);
          if (this.cookieService.check(this.constants.TOKEN_HEADER)) {
            this.auth.clearCacheData();
            location.reload();
          }
        }
      );
  }

  resetPassword() {
    this.auth
      .resetUser(this.loginForm?.value)
      ?.pipe(takeWhile(() => this.alive))
      .subscribe(
        (res) => this.loginSuccess(res),
        (error) => this.auth.handleError(error)
      );
  }

  loginSuccess(data: any) {
    data = data?.body;
    const validateUser = data?.responseStatus;
    if (
      validateUser?.statusCode === 200 &&
      validateUser.statusMessage.toLowerCase() === "authentication successful!"
    ) {
      this.userData = data.userName;
      this.auth?.setName(this.userData);
      const date = new Date();
      date.setTime(+date + this.constants.cookieExpireTime * 3600000); // set the cookie time to 12 hours
      this.cookieService.set(this.constants.TOKEN_HEADER, data.token, date);
      this.cookieService.set(
        this.constants.API_TOKEN,
        data.apiAccessToken,
        date
      );
      localStorage.setItem(this.constants.USER_NAME, `${data?.userName}`);
      localStorage.setItem(this.constants.USER_ROLE, `${data?.role}`);
      localStorage.setItem(
        this.constants.USER_FULL_NAME,
        `${data?.firstName} ${data?.lastName}`
      );
      this.router.navigate(["home"]);
    } else if (validateUser?.statusCode === 406) {
      let newPassword = new FormControl("", [
        Validators.required,
        Validators.pattern(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{4,12}$"
        ),
      ]);
      this.loginForm.addControl("newPassword", newPassword);
      this.showNewPassword = true;
    } else {
      this.loginForm.controls["password"].setErrors({
        invalidCredentials: true,
      });
    }
  }

  // to reset the password in reset component
  passwordReset(): void {
    this.router.navigate(["reset-password"]);
  }

  show: boolean = false;
  showPassword() {
    this.show = !this.show;
  }
}
