<div class="filetrackingContainer containerPadding">
  <div class="search-clear-container">
    <form [formGroup]="filetrackingform" class="filetrackingform">
      <div class="searchFilterContainer mr_19">
        <span class="mr_19">
          <p class="searchHeading">{{ constants?.file_search }}</p>
          <mat-form-field class="example-form-field" appearance="outline">
            <input
              matInput
              type="text"
              placeholder="Search (file name, ID)"
              (keyup)="searchFilter($event)"
              formControlName="searchValue"
              onkeydown="return event.key != 'Enter'"
            />
            <img
              src="../../../app/assets/images/search-icon.svg"
              alt="search"
              height="15px"
              width="15px"
              title="search"
              alt=""
            />
          </mat-form-field>
        </span>
        <span>
          <p class="searchHeading">{{ constants?.source }}</p>
          <mat-form-field appearance="outline" class="dropdown_icon">
            <mat-select
              (selectionChange)="documentTypeSelected($event)"
              formControlName="documentType"
              [placeholder]="placeholder"
            >
              <mat-option *ngFor="let type of documentTypeList" [value]="type">
                {{ type.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
      </div>

      <div class="receiptAndAttributeBlock">
        <span class="receiptDate">
          <button mat-button type="button" (click)="picker.open()">
            {{ applyReceiptDateLabel }}
          </button>
          <span>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
              <input matStartDate formControlName="start" />
              <input
                matEndDate
                formControlName="end"
                (dateChange)="filterTableWithDateRange($event)"
              />
            </mat-date-range-input>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </span>
        </span>
      </div>
    </form>
    <span class="clearBTN" *ngIf="isFiltered" (click)="clearFilter()">
      {{ constants?.clear_filters }}
    </span>
  </div>

  <div class="tableContainer">
    <table
      id="fileTrackingTable"
      class="tableCSS"
      mat-table
      [dataSource]="dataSource"
      matSort
    >
    <caption></caption>
      <ng-container matColumnDef="downloadInBound">
        <th mat-header-cell *matHeaderCellDef>
          {{ constants?.download_inbound }}
        </th>
        <td mat-cell *matCellDef="let element">
          <button
            class="download-button"
            (click)="downloadInOrOutBound(element.fileName , 'inbound')"
            *ngIf="element?.fileStages.INGESTION.STATUS !== 'FAILED'"
            [disabled]="element?.inboundUrl === null"
            [ngClass]="element?.inboundUrl === null ? 'disableDownload': ''"
          >
            <img
              src="../../../app/assets/images/download-icon.svg"
              alt="Download InBound Icon"
              title="Download InBound"
            />
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="downloadOutBound">
        <th mat-header-cell *matHeaderCellDef>
          {{ constants?.download_outbound }}
        </th>
        <td mat-cell *matCellDef="let element">
          <button 
            class="download-button"
            (click)="downloadInOrOutBound(element.outboundUrl , 'outbound')"
            *ngIf="element?.fileStages.INGESTION.STATUS !== 'FAILED'"
            [disabled] = "element?.fileStages.FILEREVIEW.STATUS === 'INPROGRESS' || element?.fileStages.AUTOMATEDASSESSMENT.STATUS === 'INPROGRESS'"
            [ngClass]= "element?.fileStages.FILEREVIEW.STATUS === 'INPROGRESS'
             || element?.fileStages.AUTOMATEDASSESSMENT.STATUS === 'INPROGRESS'? 'disableDownload': ''"
          >
            <img
              src="./../../app/assets/images/download-icon.svg"
              alt="Download OutBound Icon"
              title="Download OutBound"
            />
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="fileName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ constants?.inbound_file_name }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.fileName }}</td>
      </ng-container>

      <ng-container matColumnDef="documentID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ constants?.id }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.documentID }}</td>
      </ng-container>

      <ng-container matColumnDef="receiptDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ constants?.intake_date }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.receiptDate | date: "dd-MMM-yyyy HH:mm:ss" | uppercase }}
        </td>
      </ng-container>

      <ng-container matColumnDef="documentType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ constants?.source }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.source }}</td>
      </ng-container>

      <ng-container matColumnDef="INT">
        <th mat-header-cell *matHeaderCellDef>{{ constants?.int }}</th>
        <td mat-cell *matCellDef="let element">
          <div
          title="{{element?.fileStages.INGESTION.STATUS === 'COMPLETED'?'Completed': 
          element?.fileStages.INGESTION.STATUS === 'FAILED'? 'Failed' :  ''}}"
            [ngClass]="{
              greenColor: element?.fileStages.INGESTION.STATUS === 'COMPLETED',
              redColor: element?.fileStages.INGESTION.STATUS === 'FAILED',
              blueColor: element?.fileStages.INGESTION.STATUS === 'INPROGRESS',
              greyColor:
                element?.fileStages.INGESTION.STATUS === 'NOT_STARTED' ||
                element?.fileStages.INGESTION.STATUS === 'NOTAPPLICABLE'
            }"
            (click)="openDialog(element)"
          ></div>
        </td>
      </ng-container>

      <ng-container matColumnDef="AA">
        <th mat-header-cell *matHeaderCellDef>{{ constants?.aa }}</th>
        <td mat-cell *matCellDef="let element" >
          <ng-container
            *ngTemplateOutlet="
              colorcode;
              context: { elementInfo: element?.fileStages.AUTOMATEDASSESSMENT }
            "
          >
         
          </ng-container> 
        </td>
      </ng-container>

      <ng-container matColumnDef="FR">
        <th mat-header-cell *matHeaderCellDef>{{ constants?.fr }}</th>
        <td mat-cell *matCellDef="let element">
          
          <ng-container
            *ngTemplateOutlet="
              colorcode;
              context: { elementInfo: element?.fileStages.FILEREVIEW }
            "
          >
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="T">
        <th mat-header-cell *matHeaderCellDef>{{ constants?.t }}</th>
        <td mat-cell *matCellDef="let element">
          <ng-container
            *ngTemplateOutlet="
              colorcode;
              context: { elementInfo: element?.fileStages.TRANSMISSION }
            "
          >
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="RQ">
        <th mat-header-cell *matHeaderCellDef>{{ constants?.rq }}</th>
        <td mat-cell *matCellDef="let element">
          <ng-container
            *ngTemplateOutlet="
              colorcode;
              context: { elementInfo: element?.fileStages.RETROSPECTIVEQUALITY }
            "
          >
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="safetyTransmission">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ constants?.safetyTransmission }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{
            element?.safetyTransmission
              ? (element.safetyTransmission
                | date: "dd-MMM-yyyy hh:mm:ss"
                | uppercase)
              : element?.fileStages.FILEREVIEW?.STATUS === "INPROGRESS"
              ? ""
              : element?.fileStages.FILEREVIEW?.STATUS === "COMPLETED"
              ? "__"
              : ""
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="qualityTransmission">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ constants?.qualityTransmission }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{
            element?.qualityTransmission
              ? (element.qualityTransmission
                | date: "dd-MMM-yyyy hh:mm:ss"
                | uppercase)
              : element?.fileStages.FILEREVIEW?.STATUS === "INPROGRESS"
              ? ""
              : element?.fileStages.FILEREVIEW?.STATUS === "COMPLETED"
              ? "__"
              : ""
          }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <div class="paginationRow">
    <div *ngIf="!resultsShow; else elseBlock" id="pageNumber">
      <p>
        {{ constants?.showing }} {{ lastPageDataCount + 1 }}
        {{ constants?.of }} {{ totalTask }} {{ constants?.results }}
      </p>
      <p>Page No: {{ currentPageNumber + 1 }}</p>
    </div>
    <ng-template #elseBlock>
      <p>
        {{ constants?.showing }} {{ this.dataSource.filteredData.length }}
        {{ constants?.of }} {{ this.dataSource.filteredData.length }}
        {{ constants?.results }}
      </p>
    </ng-template>
    <mat-paginator
      [length]="totalTask"
      [pageSize]="10"
      [pageSizeOptions]="pageSizeOptions"
      (page)="getPage($event)"
    >
    </mat-paginator>
  </div>
</div>

<ng-template #colorcode let-data="elementInfo">
  <div 
    title="{{data.STATUS === 'INPROGRESS'?'In Progress': data.STATUS === 'NOTSTARTED'?'Not Started': data.STATUS === 'NOTPOSSIBLE'?'Not Possible': data.STATUS | titlecase }}"
    [ngClass]="{
      greenColor: data?.STATUS === 'COMPLETED',
      redColor: data?.STATUS === 'FAILED',
      blueColor: data?.STATUS === 'INPROGRESS',
      whiteColor: data?.STATUS === 'NOTPOSSIBLE',
      greyColor:
        data?.STATUS === 'NOTSTARTED' || data?.STATUS === 'NOTAPPLICABLE'
    }"
  >
  </div>
</ng-template>

<!-- Color-Codes
  _______________________________
  | Blue   | In_progress        |
  | Green  | Complete           |
  | Red    | Failure            |
  | White  | NOT-POSSIBLE       |
  | Grey   |  NA / Yet to Begin |  
  |________|____________________|
-->
