import { Component, OnInit } from "@angular/core";
import { CommandCenterService } from "src/app/shared/services/command-center/command-center.service";
import { CONSTANTS } from "src/app/assets/data/i18";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { DatePipe } from "@angular/common";
import { MatRadioChange } from "@angular/material/radio";
import { AuthService } from "src/app/shared/services/authentication/auth.service";

@Component({
  selector: "app-report-metrics",
  templateUrl: "./report-metrics.component.html",
  styleUrls: ["./report-metrics.component.scss"],
})
export class ReportMetricsComponent implements OnInit {
  constant: any = CONSTANTS;
  applicationSettingsForm: FormGroup;
  reconciliationForm: FormGroup;
  adjudicationForm: FormGroup;
  isScrolled!: boolean;
  moveReconciliation!: boolean;
  moveAdjudication!: boolean;
  moveTaskAging!: boolean;
  moveApplicationSetting!: boolean;
  selectedValue: string = "";
  clickedItem = "reconciliation | adjudication | taskAging| applicationSetting";
  uploadedFileName!: string;
  isFileSelected: boolean = false;
  uploadedFileDetails: any;
  uploadFileErrorMSG: string = CONSTANTS.uploadFileErrorMSG;

  constructor(
    private commandCenter: CommandCenterService,
    public datepipe: DatePipe,
    fb: FormBuilder,
    private authService: AuthService
  ) {
    this.reconciliationForm = new FormGroup({
      startDate: new FormControl("", Validators.required),
      endDate: new FormControl("", Validators.required),
    });
    this.adjudicationForm = fb.group({
      startDate: new FormControl(null),
      endDate: new FormControl(null),
      radioOptions: new FormControl("filterByTime"),
    });
    this.applicationSettingsForm = new FormGroup({
      startDate: new FormControl(null),
      endDate: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.selectedValue = "filterByTime";
    this.uploadedFileName = CONSTANTS.emptyFileMSG;
  }

  movetoTop(divname: string) {
    this.moveReconciliation = false;
    this.moveAdjudication = false;
    this.moveTaskAging = false;
    this.moveApplicationSetting = false;
    this.isScrolled = false;
    switch (divname) {
      case "reconciliation": {
        this.isScrolled = true;
        this.clickedItem = divname;
        return;
      }
      case "adjudication": {
        this.moveReconciliation = true;
        this.clickedItem = divname;
        return;
      }
      case "taskAging": {
        this.moveReconciliation = true;
        this.moveAdjudication = true;
        this.clickedItem = divname;
        return;
      }
      case "applicationSetting": {
        this.moveReconciliation = true;
        this.moveAdjudication = true;
        this.moveTaskAging = true;
        this.clickedItem = divname;
        return;
      }
    }
  }

  // adjudication report download by time or id
  filterValueChange(event: MatRadioChange) {
    this.selectedValue = event.value;
    switch (this.selectedValue) {
      case "filterByTime": {
        this.uploadedFileName = CONSTANTS.emptyFileMSG;
        break;
      }
      case "filterById": {
        this.adjudicationForm.controls.startDate.setValue(null);
        this.adjudicationForm.controls.endDate.setValue(null);
        break;
      }
    }
  }

  // to download the required reports
  async downloadReportHandler(reportOf: string): Promise<void> {
    // filename: to download with this name
    switch (reportOf) {
      case "reconciliation": {
        const dates = this.dateTransformHandler(this.reconciliationForm.value);
        const datesforfilename = this.dateFormatDownloadFile(
          this.reconciliationForm.value
        );
        this.commandCenter
          .downloadReconciliationReport(dates.start, dates.end)
          .subscribe((data: any) => {
            const filename = `ReconciliationReport_${datesforfilename.start} "TO" ${datesforfilename.end}`;
            this.downloadFile(data, filename);
          });
        break;
      }
      case "adjudication": {
        const dates = this.dateTransformHandler(this.adjudicationForm.value);
        const datesforfilename = this.dateFormatDownloadFile(
          this.adjudicationForm.value
        );
        this.commandCenter
          .downloadAdjudicationReport(dates.start, dates.end)
          .subscribe((data: any) => {
            const filename = `AdjudicationReport_${datesforfilename.start} "TO" ${datesforfilename.end}`;
            this.downloadFile(data, filename);
          });
        break;
      }
      case "taskAging": {
        this.commandCenter.downloadTaskAgingReport().subscribe((data: any) => {
          this.downloadFile(data, "TaskagingReport");
        });
        break;
      }
      case "applicationSettings": {
        const dates = this.dateTransformHandler(
          this.applicationSettingsForm.value
        );
        const datesforfilename = this.dateFormatDownloadFile(
          this.applicationSettingsForm.value
        );
        this.commandCenter
          .getApplicationSettings(dates.start, dates.end)
          .subscribe((data: any) => {
            const filename = `ApplicationSettingsReport_${datesforfilename.start} "TO" ${datesforfilename.end}`;
            this.downloadFile(data, filename);
          });
        break;
      }
      case "uploadAndDownloadFile": {
        if (this.uploadedFileDetails) {
          let formData: FormData = new FormData();
          formData.append("file", this.uploadedFileDetails);
          const response = await this.commandCenter
            .postadjudication(formData)
            .toPromise();
          if (response.body) {
            this.downloadFile(response, "AdjudicationReport.csv");
          } else {
            this.authService.showSuccessErrorPopup(
              "error",
              this.uploadFileErrorMSG
            );
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  // upload adjudication file only csv format
  async uploadFileHandler(event: any): Promise<void> {
    const file = event?.target?.files;
    if (file && file.length) {
      this.isFileSelected = true;
      this.uploadedFileName = file[0].name;
      this.uploadedFileDetails = file[0];
    }
  }

  // to convert the date formats
  dateTransformHandler(formValue: any) {
    const transform = {
      start: this.datepipe.transform(
        formValue.startDate,
        "yyyy-MM-ddT00:00:00"
      ),
      end: this.datepipe.transform(formValue.endDate, "yyyy-MM-ddT23:59:59"),
    };
    return transform;
  }
  dateFormatDownloadFile(formValue: any) {
    const transform = {
      start: this.datepipe.transform(formValue.startDate, "dd-MM-yyyy"),
      end: this.datepipe.transform(formValue.endDate, "dd-MM-yyyy"),
    };
    return transform;
  }

  downloadFile(data: any, filename = "data") {
    let blob = new Blob(["\ufeff" + data.body], {
      type: "text/csv;charset=utf-8;",
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
  }
}
