import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { takeWhile } from "rxjs/operators";
import { ElevateSafetyDetectiveService } from "src/app/shared/services/elevateSafety-detective/elevate-safety-detective.service";
import { AuthService } from "src/app/shared/services/authentication/auth.service";
import { CONSTANTS } from "src/app/assets/data/i18";
import { FileTrackingDialogboxComponent } from "../file-tracking-dialogbox/file-tracking-dialogbox.component";
import { FileTrackingListInterface } from "src/app/assets/data/constant-values";
import {
  PaginationConstants,
  TableColumnNames,
} from "src/app/assets/data/constant-values";
import { MatSelectChange } from "@angular/material/select";

@Component({
  selector: "app-file-tracking",
  templateUrl: "./file-tracking.component.html",
  styleUrls: ["./file-tracking.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FileTrackingComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | any;
  @ViewChild(MatSort) sort: MatSort | any;
  dataSource: any = new MatTableDataSource<any>();
  displayedColumns: string[] = TableColumnNames.FILE_TRACKING;
  constants: any = CONSTANTS;
  documentTypeList: any | undefined;
  isFiltered: boolean = false;
  filetrackingform!: FormGroup;
  resultsShow: boolean = false;
  showandHideCalendar: boolean = false;
  applyReceiptDateLabel: any = CONSTANTS?.intake_date;
  range = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });
  placeholder = CONSTANTS?.source;
  private alive = true;
  filterDictionary = new Map<string, string>();
  //Pagination Constants
  totalTask: number = 0;
  itemPerPage: number = PaginationConstants.itemPerPage;
  pageSizeOptions: number[] = PaginationConstants.pageSizeOptions;
  currentPageNumber: number = PaginationConstants.currentPageNumber;
  lastPageDataCount: number = PaginationConstants.lastPageDataCount;

  constructor(
    fb: FormBuilder,
    public elevateSafetyDetectiveService: ElevateSafetyDetectiveService,
    public authService: AuthService,
    public datepipe: DatePipe,
    public dialog: MatDialog
  ) {
    this.filetrackingform = fb.group({
      searchValue: new FormControl(""),
      documentType: new FormControl(""),
    });
  }

  ngOnInit() {
    this.getFilterTrackingList();
    this.getDropdownDataFromStorage();
    this.applyFilterPredicate();
    this.sortingDataAccessor();
    let activeState =
      this.elevateSafetyDetectiveService?.taskQueueFilterValues?.activeState;
    activeState = false;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getFilterTrackingList() {
    this.elevateSafetyDetectiveService
      .getFilterTrackingData()
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (data) => this.fileTrackingList(data?.body),
        (error) => this.authService.handleError(error)
      );
  }

  fileTrackingList(data: any) {
    this.dataSource.data = data;
    this.totalTask = this.dataSource.data?.length;
    this.getPageDataCount();
  }

  getDropdownDataFromStorage(): void {
    let dropdownData: any = JSON.parse(
      localStorage.getItem(this.constants.dropdown_data) || "{}"
    );
    this.documentTypeList = dropdownData?.SOURCE_TYPE;
  }

  applyFilterPredicate(): void {
    const me = this;
    this.dataSource.filterPredicate = function (record: any, filter: any) {
      let map: any = new Map(JSON.parse(filter));
      let isMatch = false;
      for (let [key, value] of map) {
        if (key === "documentID") {
          isMatch =
            record[key as keyof FileTrackingListInterface]
              .trim()
              .toLowerCase()
              .indexOf(value) != -1 ||
            record["fileName" as keyof FileTrackingListInterface]
              .trim()
              .toLowerCase()
              .indexOf(value) != -1;
        } else if (key === "receiptDate") {
          let created: any = me.datepipe.transform(
            record[key as keyof FileTrackingListInterface],
            "dd-MMM-YYYY"
          );
          if (value && value.split(" ").length > 1) {
            const startDate =
              me.datepipe.transform(value.split(" ")[0], "dd-MMM-YYYY") || "";
            const endDate =
              me.datepipe.transform(value.split(" ")[1], "dd-MMM-YYYY") || "";
            isMatch =
              new Date(created) >= new Date(startDate) &&
              new Date(created) <= new Date(endDate);
          } else if (value && value.split(" ").length == 1) {
            isMatch =
              created ==
              me.datepipe.transform(value.split(" ")[0], "dd-MMM-YYYY");
          }
        } else {
          isMatch =
            record[key as keyof FileTrackingListInterface] == value?.value;
        }
        if (!isMatch) return false;
      }
      return isMatch;
    };
  }

  sortingDataAccessor(): void {
    this.dataSource.sortingDataAccessor = (item: any, property: any) => {
      switch (property) {
        case "receiptDate":
          return new Date(item.receiptDate);
        case "documentType":
          return item.source;
        default:
          return item[property];
      }
    };
  }

  searchFilter(event: any): void {
    this.filterDictionary.set(
      "documentID",
      this.filetrackingform.value.searchValue?.trim().toLowerCase()
    );
    this.dataSource.filter = JSON.stringify(
      Array.from(this.filterDictionary.entries())
    );
    this.showHideFilterButton(true);
  }

  documentTypeSelected(ob: MatSelectChange): void {
    this.filterDictionary.set("source", ob.value);
    this.dataSource.filter = JSON.stringify(
      Array.from(this.filterDictionary.entries())
    );
    this.showHideFilterButton(true);
  }

  filterTableWithDateRange(event: any) {
    this.isFiltered = true;
    const startDate = this.datepipe.transform(
      this.range.value.start,
      "dd-MMM-YYYY"
    );
    const endDate = this.datepipe.transform(
      this.range.value.end,
      "dd-MMM-YYYY"
    );
    if (
      this.datepipe.transform(this.range.value.end, "dd-MMM-YYYY") === null ||
      startDate === endDate
    ) {
      this.applyReceiptDateLabel = this.datepipe.transform(
        this.range.value.start,
        "dd-MMM-YYYY"
      );
    } else {
      this.applyReceiptDateLabel =
        this.datepipe.transform(this.range.value.start, "dd-MMM-YYYY") +
        " " +
        this.datepipe.transform(this.range.value.end, "dd-MMM-YYYY");
    }
    this.filterDictionary.set("receiptDate", this.applyReceiptDateLabel);
    this.dataSource.filter = JSON.stringify(
      Array.from(this.filterDictionary.entries())
    );
    this.showHideFilterButton(true);
  }

  openDialog(data: any) {
    if (data.fileStages?.INGESTION?.STATUS == "FAILED") {
      this.dialog.open(FileTrackingDialogboxComponent, {
        data: data,
      });
    }
  }

  downloadInOrOutBound(fileName: string, type: string): void {
    this.elevateSafetyDetectiveService.downloadFileBoundXml(
      fileName,
      type,
      "xml"
    );
  }

  showHideFilterButton(value: boolean): void {
    this.isFiltered = value;
    this.resultsShow = value;
  }

  clearFilter(): void {
    this.filetrackingform.reset();
    this.dataSource.filter = "";
    this.filterDictionary.clear();
    this.isFiltered = false;
    this.applyReceiptDateLabel = CONSTANTS?.intake_date;
    this.range.controls.start.setValue("");
    this.range.controls.end.setValue("");
  }

  // paginations
  getPage(event: any): void {
    this.totalTask = event?.length;
    this.itemPerPage = event.pageSize;
    this.currentPageNumber = event.pageIndex; // to get the current page number
    this.getPageDataCount();
  }

  getPageDataCount() {
    this.dataSource["_renderData"].subscribe((ele: any) => {
      //to get records-count on last page
      this.lastPageDataCount = 0;
      this.lastPageDataCount = ele?.length - 1;
    });
  }
}
