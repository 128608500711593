import { Component, OnInit } from "@angular/core";
import { ElevateSafety } from "src/app/assets/data/constant-values";
import { CONSTANTS } from "src/app/assets/data/i18";

@Component({
  selector: "home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  navLinks = ElevateSafety.NAVIGATION_LABELS;
  constants: any = CONSTANTS;
  isRoleEmpty!: boolean;

  constructor() {}

  ngOnInit() {
    const userRole = localStorage.getItem("role");
    if (userRole === "") {
      this.isRoleEmpty = true;
    } else {
      this.isRoleEmpty = false;
    }
  }
}
