import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CONSTANTS } from "src/app/assets/data/i18";
import { AuthService } from "src/app/shared/services/authentication/auth.service";
import { ElevateSafetyDetectiveService } from "src/app/shared/services/elevateSafety-detective/elevate-safety-detective.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  constants: any = CONSTANTS;
  showLogout: boolean = false;
  isRoleEmpty!:boolean;

  constructor(private router: Router, 
              public auth: AuthService,
              public elevateSafetyDetectiveService: ElevateSafetyDetectiveService ) {}

  ngOnInit(): void {
    const userRole = localStorage.getItem('role');  
    if(userRole === '') {
      this.isRoleEmpty = true;
    } else {
      this.isRoleEmpty = false;
    }
  }

  logout() {
    this.auth.clearCacheData();
    this.login();
  }

  login() {
    localStorage.removeItem("username");
    this.router.navigate(["login"]);
  }

  navigateToHomeModule(): void { // elevate-safety
    this.router.navigate(["home"]);
  }

  navigateToAdminModule(): void { // command-center
    this.router.navigate(["command-center", "reports-metrics"]);
  }

  downloadHelpFile(){
    let dropdownData:any = JSON.parse(localStorage.getItem(this.constants.dropdown_data) || '{}');
    this.elevateSafetyDetectiveService.downloadFileBoundXml(dropdownData?.HELP_DOCUMENT,'outbound', 'pdf')
  }
}
