<div class="fileReviewContainer">
    <div class="collapseRow">
        <div class="goBackRow" title="Back to queue">
            <img src="../../../app/assets/images/go-back-icon.svg" alt="go-back" title="go-back" (click)="backToList()">
            <span class="goBackTitle" *ngIf="!isCriteriaCollapsed"> {{ constants?.previousPageHeading }} </span>
        </div>
        <span (click)="collapseColumn()" [ngClass]="!isCriteriaCollapsed ? 'collapse' : 'expand'">
            <img src="../../../app/assets/images/back-arrow-icon.svg" alt="back-arrow" 
            [title]="!isCriteriaCollapsed ? '{{ constants?.collapse }}' : '{{ constants?.expand }}'">
        </span>
    </div>
    <div class="fileDetectionContainer" *ngIf="!isCriteriaCollapsed" [ngClass]="(containerEnabled) ? 'claimed': 'unClaimed'">
        <div class="detectionTypesBlock">

            <div class="headingBlock">
                <h2 class="heading">{{ constants?.eventDetectionHeading }}</h2>
                <h2 class="heading">{{ constants?.productQualityComplaintDetectionHeading }}</h2>
            </div>

            <div class="actionBTNBlock">
                <button 
                    class="completeTaskBtn" 
                    (click)="completeTask()" 
                    [ngClass]="!containerEnabled || (taskDetails.caseValidityCriteria.isEventDetected === null || 
                                    taskDetails.caseValidityCriteria.isProductQualityComplaint === null) ? 'disable-icon' : 'enable-icon'" 
                    [disabled] = "!containerEnabled || (taskDetails.caseValidityCriteria.isEventDetected === null || 
                                    taskDetails.caseValidityCriteria.isProductQualityComplaint === null)"
                > 
                    {{constants?.completeTaskHeading}} 
                </button>
                <button mat-button (click)="completeTask()" [disabled] = "!containerEnabled"> {{constants?.save}} </button>
            </div>
        </div>
        
        <div class="fileReviewCriteriaBlock">
            <h3 class="heading">{{ taskDetails?.taskType ?  taskDetails.taskType : fileReviewHeading }}</h3>
            <form>
                <div class="eventDetectionBlock">
                    <div class="headingInfo">
                        <h2 class="heading"> {{ constants?.eventDetectionHeading }} </h2>
                        <img src="../../../app/assets/images/info-icon.svg" alt="{{ constants?.eventDetectionHeading }}" 
                            title="{{taskDetails.information.CASE_VALIDITY_DEFINITION}}"
                        >
                    </div>
                    <div class="criteriaList" [ngClass]="showAllEventCriteria ? 'expandHeight' : 'collapseHeight' ">
                        <div class="eventDetectCriteria mb-24">
                            <span>
                                <h2 class="heading">{{ constants?.eventDetectionHeading }}</h2>
                                <img 
                                    class="editIcon" 
                                    *ngIf = "disableSections.eventDetectionForm  && containerEnabled" 
                                    src="../../../app/assets/images/path.png" 
                                    title="Edit" alt="Edit" 
                                    (click)="editClick('eventDetectionForm')"
                                >
                                <div class="actionBTNgroup" *ngIf = "!disableSections.eventDetectionForm">
                                    <button mat-icon-button  [disabled]="disableResetIcons.eventDetectionForm" (click)="resetReviewForms('eventDetectionForm')">
                                         <img 
                                            src="../../../app/assets/images/undo-icon.svg" 
                                            [ngClass]="disableResetIcons.eventDetectionForm ? 'disable-icon' : 'enable-icon'" 
                                            title="Reset changes" alt="Reset changes"
                                        >
                                    </button>
                                    <span class="saveCancelBTNgroup">
                                        <img 
                                            src="../../../app/assets/images/tick-icon.svg" 
                                            [ngClass]="!indicatorFlags.eventDetectionForm?'indicator-disable':''" 
                                            title="Agree" alt="Agree"
                                            (click)="changeFormsValues('eventDetectionForm','agree')"
                                        >
                                        <img 
                                            src="../../../app/assets/images/close-icon.svg" 
                                            [ngClass]="indicatorFlags.eventDetectionForm?'indicator-disable': ''" 
                                            title="Disagree" alt="Disagree"
                                            (click)="changeFormsValues('eventDetectionForm','disagree')"
                                        >
                                    </span>
                                </div>
                            </span>
                            <mat-radio-group class="example-radio-group" *ngIf = "!expansionFlags.eventDetectionForm"  [disabled]="disableSections.eventDetectionForm">
                                <mat-radio-button value="'true'"
                                    [checked]="taskDetails.caseValidityCriteria.isEventDetected === 'true'"
                                    (change)="selectedFileReview('true', 'eventDetectionForm')">
                                    {{ constants?.eventDetectedHeading }}
                                </mat-radio-button>
                                <mat-radio-button value="'false'"
                                    [checked]="taskDetails.caseValidityCriteria.isEventDetected === 'false'"
                                    (change)="selectedFileReview('false', 'eventDetectionForm')">
                                    {{ constants?.noEventDetectedHeading }}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
                <div class="qualityComplaintBlock">
                    <div class="headingInfo">
                        <h2 class="heading">{{ constants?.productQualityComplaintDetectionHeading }} </h2>
                        <img 
                            src="../../../app/assets/images/info-icon.svg" 
                            alt="{{ constants?.productQualityComplaintDetectionHeading }}" 
                            title="{{taskDetails.information.PRODUCT_QUALITY_DEFINITION}}"
                        >
                    </div>
                    <div class="criteriaList">
                        <div class="eventDetectCriteria mb-24">
                            <span>
                                <h2 class="heading">{{ constants?.productQualityComplaintDetectionHeading }}</h2>
                                <img
                                    class="editIcon" 
                                    *ngIf = "disableSections.productComplainaintForm && containerEnabled" 
                                    src="../../../app/assets/images/path.png" 
                                    title="Edit" alt="Edit" 
                                    (click)="editClick('productComplainaintForm')"
                                >
                                <div class="actionBTNgroup" *ngIf = "!disableSections.productComplainaintForm">                                    
                                    <button mat-icon-button [disabled]="disableResetIcons.productComplainaintForm" (click)="resetReviewForms('productComplainaintForm')">
                                        <img 
                                            src="../../../app/assets/images/undo-icon.svg" 
                                            [ngClass]="disableResetIcons.productComplainaintForm ? 'disable-icon' : 'enable-icon'" 
                                            title="Reset changes" alt="Reset changes"
                                        >
                                     </button>
                                    <span class="saveCancelBTNgroup">
                                        <img 
                                            src="../../../app/assets/images/tick-icon.svg" 
                                            title="Agree" alt="Agree"
                                            [ngClass]="!indicatorFlags.productComplainaintForm?'indicator-disable':''"
                                            (click)="changeFormsValues('productComplainaintForm','agree')"
                                        >
                                        <img 
                                            src="../../../app/assets/images/close-icon.svg"  
                                            title="Disagree" alt="Disagree"
                                            [ngClass]="indicatorFlags.productComplainaintForm?'indicator-disable':''"
                                            (click)="changeFormsValues('productComplainaintForm','disagree')"
                                        >
                                    </span>
                                </div>
                            </span>
                            <mat-radio-group class="example-radio-group" *ngIf="!expansionFlags.productComplainaintForm" [disabled]="disableSections.productComplainaintForm">
                                <mat-radio-button 
                                        value="'true'"
                                        [checked]="taskDetails.caseValidityCriteria.isProductQualityComplaint === 'true'"
                                        (change)="selectedFileReview('true', 'productComplainaintForm')">
                                        {{ constants?.productQualityComplaintDetectedHeadingHeading }}
                                    </mat-radio-button>
                                    <mat-radio-button 
                                        value="'false'"
                                        [checked]="taskDetails.caseValidityCriteria.isProductQualityComplaint === 'false'"
                                        (change)="selectedFileReview('false', 'productComplainaintForm')">
                                        {{ constants?.noProductQualityComplaintDetectedHeading }}
                                    </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="actionBTNBlock">
            <button 
                class="completeTaskBtn" 
                (click)="completeTask()" 
                [ngClass]="!containerEnabled || (taskDetails.caseValidityCriteria.isEventDetected === null || 
                                taskDetails.caseValidityCriteria.isProductQualityComplaint === null) ? 'disable-icon' : 'enable-icon'" 
                [disabled] = "!containerEnabled || (taskDetails.caseValidityCriteria.isEventDetected === null || 
                                taskDetails.caseValidityCriteria.isProductQualityComplaint === null)"
            > 
                {{constants?.completeTaskHeading}} 
            </button>
        </div>
    </div>
</div>
