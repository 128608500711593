import { Component, OnInit } from "@angular/core";
import { takeWhile } from "rxjs/operators";
import { CONSTANTS } from "src/app/assets/data/i18";
import { AuthService } from "src/app/shared/services/authentication/auth.service";
import { ElevateSafetyDetectiveService } from "src/app/shared/services/elevateSafety-detective/elevate-safety-detective.service";

@Component({
  selector: "app-user-info",
  templateUrl: "./user-info.component.html",
  styleUrls: ["./user-info.component.scss"],
})
export class UserInfoComponent implements OnInit {
  constants: any = CONSTANTS;
  userName: any = localStorage.getItem("USER_FULL_NAME");
  userRole = localStorage.getItem("role");
  alive: boolean = true;

  constructor(
    private elevateSafetyService: ElevateSafetyDetectiveService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    if (
      localStorage.getItem(CONSTANTS.dropdown_data) === null &&
      this.userRole
    ) {
      this.getandStoreDropdownData();
    }
    this.elevateSafetyService.taskQueueFilterValues.activeState = false;
  }

  getandStoreDropdownData() {
    this.elevateSafetyService
      .getDropdownData()
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (data) => {
          if (data && data.body) {
            localStorage.setItem(
              CONSTANTS.dropdown_data,
              JSON.stringify(data?.body)
            );
          }
        },
        (error) => this.auth.handleError(error)
      );
  }
}
