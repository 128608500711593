import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CanDeactivate } from "@angular/router";
import { Observable, Observer } from "rxjs";
import { CONSTANTS } from "src/app/assets/data/i18";
import { ReusableDialogComponent } from "../components/reusable-dialog/reusable-dialog.component";
import { DirtyComponent } from "../modules/dirty-component";

@Injectable({
  providedIn: "root",
})
export class DirtyCheckGuard implements CanDeactivate<DirtyComponent> {
  unsavedChangesMsg: string = CONSTANTS.unsavedChangesMsg;
  pageLeaveMsg: string = CONSTANTS.pageLeaveMsg;

  constructor(private dialog: MatDialog) {}

  canDeactivate(component: DirtyComponent) {
    if (!component.canDeactivate()) {
      return true;
    } else {
      return Observable.create((observer: Observer<boolean>) => {
        // UnsavedChangesDialog defined somewhere else and imported above
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          id: "funnelPopupBox",
          panelClass: "dialog-reattempt-container",
          data: {
            heading: this.unsavedChangesMsg,
            description: this.pageLeaveMsg,
            cancelBTN: "Cancel",
            saveBTN: "Confirm",
          },
        });

        dialogRef.afterClosed().subscribe(
          (result) => {
            observer.next(result);
            observer.complete();
          },
          (error) => {
            observer.next(false);
            observer.complete();
          }
        );
      });
    }
  }
}
