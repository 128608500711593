import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpClientModule,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { Constants } from "./constants";
import { throwError } from "rxjs/internal/observable/throwError";
import { catchError } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class HttpClientHelper {
  private http;
  private readonly baseURL;
  private readonly baseApiUrl;
  private readonly sysAdminApiUrl;

  static getBaseURL() {
    return location.origin;
  }

  constructor(
    http: HttpClient,
    private constants: Constants,
    private cookieService: CookieService
  ) {
    this.http = http;
    this.baseURL = HttpClientHelper.getBaseURL();

    //INNOMINDS EKS DEPLOYMENET ENDPOINTS
    this.baseApiUrl = "https://filetracking-funnel.dev-innominds.com/";
    this.sysAdminApiUrl = "https://sysadmin-funnel.dev-innominds.com/";

    //DELOITTE EKS DEPLOYMED BACKEND DEV
    // this.baseApiUrl = "https://dchs-funnel-filetracking.deloitte.elevatesafetysolutions.com/";
    // this.sysAdminApiUrl = "https://dchs-funnel-adminservice.deloitte.elevatesafetysolutions.com/";

    //DELOITTE EKS DEPLOYMED BACKEND QA
    // this.baseApiUrl = "https://dchs-funnel-qa-filetracking.deloitte.elevatesafetysolutions.com/";
    // this.sysAdminApiUrl = "https://dchs-funnel-qa-adminservice.deloitte.elevatesafetysolutions.com/";

    //INNOMINDS ELASTIC BEAN IFUSION
    // this.sysAdminApiUrl =
    //   "http://funneladmin-env.eba-rwaawyf8.ap-south-1.elasticbeanstalk.com/";
    // this.baseApiUrl =
    //   "http://filetrackingenvironment-env.eba-jf7rxmsr.ap-south-1.elasticbeanstalk.com/";
  }

  handleError(error: any) {
    if (error.status === 401) {
      return throwError(error || "Server error");
    }
    return throwError(error || "Server error");
  }

  createHttpHeaders(isFile = false): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append("Accept", "application/json");
    if (!isFile) {
      headers = headers.append("Content-Type", "application/json");
    } else {
      headers = headers.append("Content-Type", "text/csv");
    }
    if (this.cookieService.check(this.constants.TOKEN_HEADER)) {
      let user: any = localStorage.getItem("USER");
      headers = headers.append(
        this.constants.TOKEN_HEADER,
        this.cookieService.get(this.constants.TOKEN_HEADER)
      );
      headers = headers.append(
        this.constants.API_TOKEN,
        this.cookieService.get(this.constants.API_TOKEN)
      );
      headers = headers.append(this.constants.USER_NAME, user);
    } else {
      headers = headers.append(this.constants.TOKEN_HEADER, "");
      headers = headers.append(this.constants.API_TOKEN, "");
      headers = headers.append(this.constants.USER_NAME, "");
    }
    return headers;
  }

  createHttpHeadersForImage(isFile = false): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append("Accept", "*/*");
    if (!isFile) {
      headers = headers.append("Content-Type", "image/png");
    }
    if (this.cookieService.check(this.constants.TOKEN_HEADER)) {
      let user: any = localStorage.getItem("USER");
      headers = headers.append(
        this.constants.TOKEN_HEADER,
        this.cookieService.get(this.constants.TOKEN_HEADER)
      );
      headers = headers.append(
        this.constants.API_TOKEN,
        this.cookieService.get(this.constants.API_TOKEN)
      );
      headers = headers.append(this.constants.USER_NAME, user);
    }
    return headers;
  }

  createHttpHeadersUpload(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append("Accept", "text/csv");
    if (this.cookieService.check(this.constants.TOKEN_HEADER)) {
      let user: any = localStorage.getItem("USER");
      headers = headers.append(
        this.constants.TOKEN_HEADER,
        this.cookieService.get(this.constants.TOKEN_HEADER)
      );
      headers = headers.append(
        this.constants.API_TOKEN,
        this.cookieService.get(this.constants.API_TOKEN)
      );
      headers = headers.append(this.constants.USER_NAME, user);
    }
    return headers;
  }

  createHttpHeadersUploads(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append("Accept", "*");
    if (this.cookieService.check(this.constants.TOKEN_HEADER)) {
      let user: any = localStorage.getItem("USER");
      headers = headers.append(
        this.constants.TOKEN_HEADER,
        this.cookieService.get(this.constants.TOKEN_HEADER)
      );
      headers = headers.append(
        this.constants.API_TOKEN,
        this.cookieService.get(this.constants.API_TOKEN)
      );
      headers = headers.append(this.constants.USER_NAME, user);
    }
    return headers;
  }

  get(url: any, params = {}): Observable<any> {
    url = this.baseApiUrl + url;
    const headers = this.createHttpHeaders();
    return this.http
      .get(url, { headers: headers, params: params, observe: "response" })
      .pipe(catchError(this.handleError));
  }

  getS(url: any, params = {}): Observable<any> {
    url = this.sysAdminApiUrl + url;
    const headers = this.createHttpHeaders();
    return this.http
      .get(url, { headers: headers, params: params, observe: "response" })
      .pipe(catchError(this.handleError));
  }

  getCSV(url: any, params = {}): Observable<any> {
    url = this.sysAdminApiUrl + url;
    const headers = this.createHttpHeaders();
    return this.http
      .get(url, {
        headers: headers,
        params: params,
        observe: "response",
        responseType: "text" as "json",
      })
      .pipe(catchError(this.handleError));
  }

  uploadFile(url: any, data = ""): Observable<any> {
    url = this.sysAdminApiUrl + url;
    const headers = this.createHttpHeadersUpload();
    return this.http
      .post(url, data, {
        headers: headers,
        observe: "events",
        responseType: "text" as any,
        reportProgress: true,
      })
      .pipe(catchError(this.handleError));
  }

  getFilesToDownload(url: string, fileType: string): Observable<any> {
    url = this.baseApiUrl + url;
    let headers = new HttpHeaders();
    const responseType = fileType === "pdf" ? "blob" : ("text" as any);
    headers =
      fileType === "xml"
        ? headers.append("Accept", "text/xml")
        : fileType === "txt"
        ? headers.append("Accept", "text/plain")
        : (headers = headers.append("Accept", "application/pdf"));
    if (this.cookieService.check(this.constants.TOKEN_HEADER)) {
      let user: any = localStorage.getItem("USER");
      headers = headers.append(
        this.constants.TOKEN_HEADER,
        this.cookieService.get(this.constants.TOKEN_HEADER)
      );
      headers = headers.append(
        this.constants.API_TOKEN,
        this.cookieService.get(this.constants.API_TOKEN)
      );
      headers = headers.append(this.constants.USER_NAME, user);
    }
    return this.http
      .get(url, {
        headers: headers,
        params: {},
        observe: "response",
        responseType: responseType,
        reportProgress: true,
      })
      .pipe(catchError(this.handleError));
  }

  uploadTask(url: any, data = ""): Observable<any> {
    url = this.sysAdminApiUrl + url;
    const headers = this.createHttpHeadersUploads();
    return this.http
      .post(url, data, {
        headers: headers,
        observe: "events",
        responseType: "text" as any,
        reportProgress: true,
      })
      .pipe(catchError(this.handleError));
  }

  getH(url: any, params = {}): Observable<any> {
    const headers = this.createHttpHeaders();
    return this.http
      .get(url, { headers: headers, params: params, observe: "response" })
      .pipe(catchError(this.handleError));
  }

  post(url: any, data = "", params = {}): Observable<any> {
    url = this.baseApiUrl + url;
    const headers = this.createHttpHeaders();
    const body = JSON.stringify(data);
    return this.http
      .post(url, body, {
        headers: headers,
        observe: "response",
        params: params,
      })
      .pipe(catchError(this.handleError));
  }

  postS(url: any, data = "", params = {}): Observable<any> {
    url = this.sysAdminApiUrl + url;
    const headers = this.createHttpHeaders();
    const body = JSON.stringify(data);
    return this.http
      .post(url, body, {
        headers: headers,
        observe: "response",
        params: params,
      })
      .pipe(catchError(this.handleError));
  }

  postH(url: any, data = ""): Observable<any> {
    url = this.baseApiUrl + url;
    const headers = this.createHttpHeaders();
    const body = data === "" ? "{}" : JSON.stringify(data);
    return this.http
      .post(url, body, { headers: headers, observe: "response" })
      .pipe(catchError(this.handleError));
  }
}
