import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Observable } from "rxjs";
import { map, startWith, takeWhile } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { CommandCenterService } from "src/app/shared/services/command-center/command-center.service";
import { CONSTANTS } from "src/app/assets/data/i18";
import { DirtyComponent } from "src/app/shared/modules/dirty-component";
import { AuthService } from "src/app/shared/services/authentication/auth.service";
import {
  TableColumnNames,
  DropdownLists,
} from "src/app/assets/data/constant-values";

@Component({
  selector: "app-document-attribute-detection",
  templateUrl: "./document-attribute-detection.component.html",
  styleUrls: ["./document-attribute-detection.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DocumentAttributeDetectionComponent
  implements OnInit, DirtyComponent
{
  constants: any = CONSTANTS;
  @ViewChild(MatSort) sort: MatSort | any;
  dataSource = new MatTableDataSource<any>();
  documentDetectionForm!: FormGroup;
  disableSelect = new FormControl(false);
  myControl = new FormControl("");
  filteredOptions!: Observable<string[]>;
  displayedColumns: string[] = TableColumnNames.FILE_ATTRIBUTE_DETECTION;
  private alive = true;
  percentageValue: string[] = [];
  color: string = CONSTANTS.toggleBTNcolor;
  isEditable: boolean = false;
  isDirty = false;
  boundErrorMsg: string = CONSTANTS.upperBoundErrorMsg;
  attributeSavedMsg: string = CONSTANTS.attributeSavedMsg;
  body: any;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private commandCenterService: CommandCenterService,
    private authService: AuthService
  ) {
    this.documentForm(DropdownLists.RETROSPECTIVE_QUALITY_INTERFACE);
  }

  ngOnInit() {
    this.documentDetectionList();
    this.chooseBoundPercentage();
    this.getDropdownDataFromStorage();
  }

  documentForm(data: any) {
    if (data) {
      const group: any = {};
      for (let i = 0; i < data.length; i++) {
        group["lowerbound_" + i] = new FormControl();
        group["upperbound_" + i] = new FormControl();
      }
      group.automatedEnabled = new FormControl();
      group.manualEnabled = new FormControl();
      this.documentDetectionForm = this.fb.group(group);
    }
  }

  documentDetectionList(): void {
    this.commandCenterService
      .getDocumentListAttrs()
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (data) => {
          if (data?.body?.length > 0) {
            this.documentForm(data?.body);
            for (let i = 0; i < data?.body.length; i++) {
              this.documentDetectionForm.controls["lowerbound_" + i]?.setValue(
                data.body[i].lowerBound + "%"
              );
              this.documentDetectionForm.controls["upperbound_" + i]?.setValue(
                data.body[i].upperBound + "%"
              );
              data.body[i].isEditEnable = false;
            }
            this.dataSource.data = data?.body;
            this.disableAllDropDown();
            setTimeout(() => (this.dataSource.sort = this.sort));
            this.isDirty = false;
          }
        },
        (error) => this.authService.handleError(error)
      );
  }

  disableAllDropDown() {
    for (const i in this.dataSource.data) {
      this.documentDetectionForm.controls["lowerbound_" + i].disable();
      this.documentDetectionForm.controls["upperbound_" + i].disable();
    }
  }

  selectedLowerbound(event: any, i: number) {
    if (
      this.documentDetectionForm.controls["lowerbound_" + i]?.value >=
      this.documentDetectionForm.controls["upperbound_" + i]?.value
    ) {
      this.showError(i);
    } else {
      this.dataSource.data[i].lowerBound =
        this.documentDetectionForm.controls["lowerbound_" + i]?.value.split(
          "%"
        )[0];
    }
  }

  selectedUpperbound(event: any, i: number) {
    const upperBoundSelected = event["option"]?.value?.split("%")[0];
    if (this.dataSource?.data[i]?.lowerBound >= upperBoundSelected) {
      this.showError(i);
    } else {
      this.dataSource.data[i].upperBound =
        this.documentDetectionForm.controls["upperbound_" + i]?.value.split(
          "%"
        )[0];
    }
  }

  enableRow(i: number, row: any) {
    row.isEditEnable = !row.isEditEnable;
    this.documentDetectionForm.controls["lowerbound_" + i]?.enable();
    this.documentDetectionForm.controls["upperbound_" + i]?.enable();
    this.isDirty = true;
  }

  canDeactivate() {
    return this.isDirty;
  }

  save(i: number, row: any): void {
    this.commandCenterService.saveDocumentAttrs(row).subscribe((data: any) => {
      if (data?.status == 200) {
        this.authService.showSuccessErrorPopup(
          "success",
          this.attributeSavedMsg
        );
        row.isEditEnable = !row.isEditEnable;
        this.documentDetectionForm.controls["lowerbound_" + i].disable();
        this.documentDetectionForm.controls["upperbound_" + i].disable();
        this.documentDetectionForm.controls["lowerbound_" + i]?.setValue(
          data.body.lowerBound + "%"
        );
        this.documentDetectionForm.controls["upperbound_" + i]?.setValue(
          data.body.upperBound + "%"
        );
        this.documentDetectionForm.controls["automatedEnabled"]?.setValue(
          data.body.automatedDetectionEnabled
        );
        this.documentDetectionForm.controls["manualEnabled"]?.setValue(
          data.body.manualReviewEnabled
        );
        this.isDirty = false;
      }
    }),
      (error: any) => {
        this.authService.handleError(error);
        this.isDirty = true;
      };
  }

  showError(i: number) {
    this.authService.showSuccessErrorPopup("error", this.boundErrorMsg);
    this.documentDetectionForm.controls["upperbound_" + i]?.setValue("");
  }

  // to select values from autocomplete for both bounds
  chooseBoundPercentage() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value || ""))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.percentageValue.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  // to get the dynamic dropdown value.
  getDropdownDataFromStorage(): void {
    let dropdownData: any = JSON.parse(
      localStorage.getItem(this.constants.dropdown_data) || "{}"
    );
    this.percentageValue = dropdownData?.SAMPLING_RATE;
  }
}
