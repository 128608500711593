<div class="login-card-container">
  <div class="funnel-header"> {{constant?.elevateSafety_detective}}</div>
  <div class="login-form-container">
    <mat-card class="card">
      <mat-card-title class="funnel-title">{{constant?.login}}</mat-card-title>
      <mat-card-content>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" novalidate class="d-flex-center">
          <div class="example-container">
            <div>
              <mat-form-field>
                <label>{{constant?.email}}</label>
                <input matInput formControlName="userName" id="username">
                <mat-error
                  *ngIf="!loginForm.get('userName')?.errors?.required && (loginForm.get('userName')?.errors?.pattern)">
                  {{constant?.errorMsg_email}}
                </mat-error>
                <mat-error
                  *ngIf="loginForm.get('userName')?.invalid && (loginForm.get('userName')?.dirty || loginForm.get('userName')?.touched) && loginForm.get('userName')?.errors?.required">
                  {{constant?.email_required}}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="mt-top-2">
              <mat-form-field>
                <label>{{constant?.password}}</label>
                <div class="password-with-icon">
                  <input matInput [type]="show ? 'text' : 'password'" formControlName="password">
                  <mat-icon (click)="showPassword()" [ngClass]="show ? 'showPass' : 'hidePass' "> 
                    remove_red_eye
                  </mat-icon>
                </div>
                <mat-error *ngIf="loginForm?.get('password')?.errors?.invalidCredentials">
                  {{credentialsErrorMsg}}
                </mat-error>
                <mat-error *ngIf="loginForm.get('password')?.errors?.pattern?.requiredPattern">
                  {{constant?.errorMsg_password}}
                </mat-error>
                <mat-error *ngIf="loginForm.get('password')?.invalid && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched) 
                 && loginForm.get('password')?.errors?.required">
                  {{constant?.password_required}}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="mt-top-2" *ngIf="showNewPassword">
              <mat-form-field>
                <label>{{constant?.newPassword}}</label>
                <input matInput type="password" formControlName="newPassword">
                <mat-error *ngIf="!loginForm.get('new_password')?.errors?.required">
                  {{constant?.errorMsg_password}}
                </mat-error>
                <mat-error
                  *ngIf="loginForm.get('newPassword')?.invalid && (loginForm.get('newPassword')?.dirty || loginForm.get('newPassword')?.touched) && loginForm.get('newPassword')?.errors?.required">
                  {{constant?.password_required}}
                </mat-error>
              </mat-form-field>
            </div>

            <!-- reset password -->
            <div id="reset-password" (click)="passwordReset()">{{constant?.forgot_password}}</div>

            <div class="login-button-container" *ngIf="!showNewPassword">
              <button [disabled]="!loginForm.valid" class="login-container" mat-raised-button color="primary"
                mat-button>
                {{constant?.login}}
              </button>
            </div>
            <div class="login-button-container" *ngIf="showNewPassword">
              <button [disabled]="!loginForm.valid" class="login-container" mat-raised-button color="primary"
                mat-button>
                {{constant?.reset}}
              </button>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
