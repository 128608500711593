<div class="headerContainer">
  <div class="elevateModule">
    <img
      src="../../../app/assets/images/deloitte-logo.svg"
      id="deloitte-logo"
      title="deloitte-logo"
      alt="deloitte-logo"
      (click)="navigateToHomeModule()"
    />
    <p title="ElevateSafety™ Detective by ConvergeHEALTH" (click)="navigateToHomeModule()">
      {{ constants?.header_fileTraking_heading }} 
    </p>
  </div>
  <div class="adminModule">
    <p (click)="navigateToAdminModule()" title="Command Center" alt="CommandCenter-heading" *ngIf="!isRoleEmpty">
      {{ constants?.header_admin_heading }} 
    </p>
    <span class="iconsRow">
      <img
        src="../../../app/assets/images/user-icon.svg"
        title="logout"
        alt="user-icon"
        (click)="showLogout = !showLogout"
      />
      <img
        *ngIf="!isRoleEmpty"
        src="../../../app/assets/images/help-icon.svg"
        alt="help-icon"
        title="help"
        (click)="downloadHelpFile()"
      />
    </span>
  </div>
</div>

<div class="userActionBTN" *ngIf="showLogout">
  <h2 (click)="logout()">
    <mat-icon>logout</mat-icon>
    {{ constants?.logout }}
  </h2> 
</div>
