import { Component, OnInit } from "@angular/core";
import { CommandCenterService } from "../../shared/services/command-center/command-center.service";
import { takeWhile } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { CONSTANTS } from "src/app/assets/data/i18";
import { DirtyComponent } from "src/app/shared/modules/dirty-component";
import { AuthService } from "src/app/shared/services/authentication/auth.service";
import { TableColumnNames } from "src/app/assets/data/constant-values";

@Component({
  selector: "app-tooltips",
  templateUrl: "./tooltips.component.html",
  styleUrls: ["./tooltips.component.scss"],
})
export class TooltipsComponent implements OnInit, DirtyComponent {
  constants: any = CONSTANTS;
  displayedColumns: string[] = TableColumnNames.TOOLTIPS;
  private alive: boolean = true;
  public miscellaneousData = {
    caseValidityText: "",
    productionComplaintText: "",
    supportEmail: "",
    supportInstruction: "",
    supportName: "",
  };
  caseValidityDisable: boolean = true;
  productComplaintDisable: boolean = true;
  supportInformationDisable: boolean = true;
  isDirty = false;
  tooltipsSaveMsg: string = CONSTANTS.tooltipSaveMsg;

  constructor(
    private commandCenterService: CommandCenterService,
    public dialog: MatDialog,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.getMiscellaneousData();
  }

  keyPressNumbers(event: any) {
    event.preventDefault();
  }

  getMiscellaneousData() {
    this.commandCenterService
      .getMiscellaneousData()
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (data) => {
          if (data?.body && data.body.miscId) {
            this.miscellaneousData = data.body;
          }
          this.isDirty = false;
        },
        (error) => this.authService.handleError(error)
      );
  }

  passObject(): any {
    const data = {
      status: "unsaved changes",
      description:
        "Are you sure you would like to exit miscellaneous attribute task? This action cannot be undone.",
      documentAttributeType: false,
    };
    return data;
  }

  enableRow(rowName: string) {
    switch (rowName) {
      case "caseDefination":
        this.caseValidityDisable = false;
        break;
      case "productDefination":
        this.productComplaintDisable = false;
        break;
      case "supportInstruction":
        this.supportInformationDisable = false;
        break;
    }
    this.isDirty = true;
  }

  canDeactivate() {
    return this.isDirty;
  }

  save(row: any): void {
    this.commandCenterService
      .saveMiscellaneousData(this.miscellaneousData)
      .subscribe(
        (data: any) => {
          if (data?.status == 200) {
            this.caseValidityDisable = true;
            this.productComplaintDisable = true;
            this.supportInformationDisable = true;
            this.authService.showSuccessErrorPopup(
              "success",
              this.tooltipsSaveMsg
            );
            this.isDirty = false;
          } else {
            //TODO Error Dialog Box
          }
        },
        (error: any) => {
          this?.authService?.handleError(error);
          this.isDirty = true;
        }
      );
  }

  onkeydown(event: any) {
    if (event.keyCode == 61 || (event.shiftKey && event.keyCode == 42)) {
      event.preventDefault();
    }
  }
}
