<div class="taskViewerContainer">
  <div class="taskDetailsRow">
    <div class="headingANDClaimedBox">
      <h2 class="docHeading">
        {{ taskType }} | {{ constants?.fileHeading }} #{{ documentId }}
      </h2>
      <div class="claimRow">
        <h2>{{ constants?.claim }}</h2>
        <mat-slide-toggle [color]="color" [(ngModel)]="checked" (change)="isClaimed($event)" [disabled]="disableToggle">
        </mat-slide-toggle>
      </div>
    </div>

    <div class="downloadFileRow">
      <img class="fileIcon" src="../../../app/assets/images/file-icon.svg" alt="file-icon" title="file-icon" />
      <h2>{{ fileName }}</h2>
      <button class="borderNone">
        <img src="../../../app/assets/images/download-icon.svg" title="download" alt="download-icon" class="download-icon"
          href="default.asp" (click)="downloadFile(null)" />
      </button>
    </div>
  </div>

  <!-- Tabs list -->
  <div>
    <div class="taskViewerLinks">
      <span *ngFor="let link of taskViewerLinks; let i = index" (click)="selectedViewerType(link)"
        [ngClass]="[classFocus['icon' + i] ? 'icon-' + i + '-active' : 'icon-' + i]">
        <img src="{{link.url}}" title="{{link.label}}" alt="link-label">
      </span>
    </div>
    <div [ngSwitch]="viewBasedUpon">
      <ng-container *ngSwitchCase="'case-narrative'">
        <ng-container *ngTemplateOutlet="viewCaseNarrative"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'full-source-document'">
        <ng-container *ngTemplateOutlet="viewFullSourceDocument"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'attachments'">
        <ng-container *ngTemplateOutlet="viewAttachments"></ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #viewCaseNarrative>
  <div class="view-case-narrative">
    {{showCaseNarrative}}
  </div>
</ng-template>

<ng-template #viewFullSourceDocument>
  <!-- JSON EDITOR -->
  <div class="JSONeditorBlock view-full-source-document">
    <json-editor class="json-editor" [options]="options" [data]="options.schema"></json-editor>
  </div>
</ng-template>

<ng-template #viewAttachments>
  <div class="view-attachments">
    <div *ngFor="let attachements of downloadAttachments; let i = index" class="download-attachments-row">
      <span>{{ constants?.attachment }} {{i+1}}</span>
      <button mat-button class="download-button" (click)="downloadFile(attachements)">
        {{ constants?.download }}
      </button>
    </div>
  </div>
</ng-template>
