import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CONSTANTS } from "src/app/assets/data/i18";

@Component({
  selector: "app-file-tracking-dialogbox",
  templateUrl: "./file-tracking-dialogbox.component.html",
  styleUrls: ["./file-tracking-dialogbox.component.scss"],
})
export class FileTrackingDialogboxComponent implements OnInit {
  constants: any = CONSTANTS;
  leftContent: any[] = [];
  rightContent: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<FileTrackingDialogboxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    const date = this.datepipe.transform(
      this.data?.receiptDate,
      "dd-MMM-yyyy hh:mm:ss"
    );
    const leftData = [
      { heading: CONSTANTS?.fileNameHeading, data: this.data?.fileName },
      {
        heading: CONSTANTS?.errorRejectHeading,
        data: this.data?.fileStages?.INGESTION.STATUS,
      },
      { heading: CONSTANTS?.timestampGMTHeading, data: date },
    ];
    const rightData = [
      {
        heading: CONSTANTS?.supportInformationHeading,
        data: this.data?.fileStages?.INGESTION?.Instruction,
      },
      { heading: this.data?.fileStages?.INGESTION?.Name, data: null },
      { heading: this.data?.fileStages?.INGESTION?.Email, data: null },
    ];
    this.leftContent = leftData;
    this.rightContent = rightData;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
