import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { CONSTANTS } from "src/app/assets/data/i18";
import { AuthService } from "src/app/shared/services/authentication/auth.service";
import { CommandCenterService } from "src/app/shared/services/command-center/command-center.service";

@Component({
  selector: "app-upload-file",
  templateUrl: "./upload-file.component.html",
  styleUrls: ["./upload-file.component.scss"],
})
export class UploadFileComponent implements OnInit {
  constant: any = CONSTANTS;
  selectedValue: string = "";
  uploadedFileName!: string;
  adjudicationForm!: FormGroup;
  isFileSelected: boolean = false;
  uploadedFileDetails: any;
  uploadFileErrorMSG: string = CONSTANTS.uploadFileErrorMSG;
  constructor(
    private commandCenter: CommandCenterService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.selectedValue = "filterByTime";
    this.uploadedFileName = CONSTANTS.emptyFileMSG;
  }

  async uploadFileHandler(event: any): Promise<void> {
    const file = event?.target?.files;
    if (file && file?.length) {
      this.isFileSelected = true;
      this.uploadedFileName = file[0].name;
      this.uploadedFileDetails = file[0];
    }
  }

  async uploadAndDownloadFile() {
    if (this.uploadedFileDetails) {
      let formData: FormData = new FormData();
      formData.append("file", this.uploadedFileDetails);
      this.commandCenter
        ?.downloaduploadfiles(formData)
        .subscribe((res: any) => {
          if (res?.status === 200) {
            this.authService.showSuccessErrorPopup(
              "success",
              "Sucessfully uploaded"
            );
          }
          return false;
        });
    }
  }
}
